import { Box, Button, CardMedia, Grid, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import NewsItem from "./commons/NewsItem";

import { Link } from "react-router-dom";
import axios from "axios";

function News() {
    const [news, setNews] = useState([]);

    function formatDate(date) {
        const months = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
        ];
        const newDate = new Date(date);

        return `${newDate.getDate()} de ${
            months[newDate.getMonth()]
        } de ${newDate.getFullYear()}`;
    }

    useEffect(() => {
        const loadNews = async () => {
            const resp = await axios.get(
                `https://mw2c0cho61.execute-api.sa-east-1.amazonaws.com/production/news?limit=10`,
                {
                    headers: {
                        "Content-Type": "application/json;charset=utf-8",
                    },
                    crossDomain: true,
                }
            );

            setNews(resp.data);
        };

        loadNews();
    }, []);

    return (
        <Fragment>
            {news && news.length !== 0 && (
                <Grid
                    container
                    marginY={6}
                    gap={4}
                    display={"flex"}
                    paddingX={8}
                >
                    <Grid
                        xs={12}
                        md={12}
                        marginTop={4}
                        color="#010101"
                        borderBottom={1}
                    >
                        <Typography
                            color="#010101"
                            width={"fit-content"}
                            borderBottom={1}
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="800"
                            fontSize="20px"
                            lineHeight="23px"
                        >
                            Notícias
                        </Typography>
                    </Grid>

                    <Grid
                        xs={12}
                        md={5.5}
                        display={"flex"}
                        style={{ maxWidth: "600px" }}
                        flexDirection={"column"}
                    >
                        <Link
                            aria-label="noticias"
                            to={`/noticia?link=${news[0].link}`}
                            rel="noreferrer"
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <CardMedia
                                component="img"
                                image={
                                    news[0].capa.includes("aws")
                                        ? news[0].capa
                                        : `https://admin.sindsep-sp.org.br/sistema/noticias/${news[0].ID}/capa/${news[0].capa}`
                                }
                                style={{
                                    maxWidth: "600px",
                                    maxHeight: "400px",
                                    objectFit: "fill",
                                }}
                                alt="collection image"
                            />
                            <Grid container display={"flex"} gap={2} mt={1}>
                                <Grid xs={6} md={"auto"}>
                                    <Typography
                                        color="#010101"
                                        fontFamily="Raleway"
                                        fontStyle="normal"
                                        fontWeight="400"
                                        fontSize="12px"
                                        lineHeight="14px"
                                    >
                                        {formatDate(news[0].incData)}
                                    </Typography>
                                </Grid>
                                <Grid
                                    xs={"auto"}
                                    md={"auto"}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        padding: "2px 12px",
                                        backgroundColor: news[0].categorieColor,
                                        borderRadius: 50,
                                    }}
                                >
                                    <Typography
                                        color="#010101"
                                        fontFamily="Raleway"
                                        fontStyle="normal"
                                        fontWeight="700"
                                        fontSize="10px"
                                        lineHeight="14px"
                                        letterSpacing={"0.1em"}
                                    >
                                        {news[0].categorie}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Typography
                                color="#010101"
                                fontFamily="Raleway"
                                fontStyle="normal"
                                fontWeight="700"
                                fontSize="24px"
                                lineHeight="28px"
                                marginTop={2}
                            >
                                {news[0].titulo}
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid
                        xs={12}
                        md={2.8}
                        display={"flex"}
                        flexDirection={"column"}
                        borderLeft={1}
                        borderRight={1}
                        padding={"0px 8px"}
                        alignItems={"center"}
                    >
                        <NewsItem
                            image={
                                news[1].capa.includes("aws")
                                    ? news[1].capa
                                    : `https://admin.sindsep-sp.org.br/sistema/noticias/${news[1].ID}/capa/${news[1].capa}`
                            }
                            date={formatDate(news[1].incData)}
                            description={news[1].titulo}
                            link={news[1].link}
                            categorie={news[1].categorie}
                            categorieColor={news[1].categorieColor}
                        />

                        <NewsItem
                            image={
                                news[2].capa.includes("aws")
                                    ? news[2].capa
                                    : `https://admin.sindsep-sp.org.br/sistema/noticias/${news[2].ID}/capa/${news[2].capa}`
                            }
                            date={formatDate(news[2].incData)}
                            description={news[2].titulo}
                            link={news[2].link}
                            categorie={news[2].categorie}
                            categorieColor={news[2].categorieColor}
                        />
                    </Grid>
                    <Grid
                        xs={12}
                        md={2.8}
                        display={"flex"}
                        flexDirection={"column"}
                        gap={2}
                    >
                        {[news[3], news[4], news[5], news[6], news[7], news[8], news[9]].map(
                            (item, index) => (
                                <Box
                                    borderBottom={1}
                                    paddingBottom={2}
                                    key={index}
                                >
                                    <Link
                                        aria-label="noticias"
                                        to={`/noticia?link=${item.link}`}
                                        rel="noreferrer"
                                        style={{
                                            textDecoration: "none",
                                        }}
                                    >
                                        <Grid container display={"flex"}>
                                            <Grid xs={"auto"} md={"auto"}>
                                                <Typography
                                                    color="#010101"
                                                    fontFamily="Raleway"
                                                    fontStyle="normal"
                                                    fontWeight="400"
                                                    fontSize="12px"
                                                    lineHeight="14px"
                                                >
                                                    {formatDate(item.incData)}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                xs={"auto"}
                                                md={"auto"}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    padding: "2px 12px",
                                                    backgroundColor:
                                                        item.categorieColor,
                                                    borderRadius: 50,
                                                    marginLeft: 8,
                                                }}
                                            >
                                                <Typography
                                                    color="#010101"
                                                    fontFamily="Raleway"
                                                    fontStyle="normal"
                                                    fontWeight="700"
                                                    fontSize="10px"
                                                    lineHeight="14px"
                                                    letterSpacing={"0.1em"}
                                                >
                                                    {item.categorie}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Typography
                                            color="#010101"
                                            fontFamily="Raleway"
                                            fontStyle="normal"
                                            fontWeight="600"
                                            fontSize="14px"
                                            lineHeight="16px"
                                            marginTop={1}
                                        >
                                            {item.titulo
                                                ? item.titulo
                                                : item.description}
                                        </Typography>
                                    </Link>
                                </Box>
                            )
                        )}
                    </Grid>
                </Grid>
            )}
            <Grid
                xs={12}
                md={12}
                padding={"16px 0px"}
                color="#FFFFFF"
                borderBottom={1}
                display={"flex"}
                justifyContent={"center"}
            >
                <Link
                    to="/noticias"
                    aria-label="link para noticias"
                    style={{
                        textDecoration: "none",
                    }}
                >
                    <Button
                        size="small"
                        variant="outlined"
                        style={{
                            borderRadius: 100,
                            color: "#EB2227",
                            padding: "8px 16px",
                            fontFamily: "Raleway",
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "20px",
                            borderColor: "#EB2227",
                        }}
                    >
                        LEIA MAIS NOTÍCIAS
                    </Button>
                </Link>
            </Grid>
        </Fragment>
    );
}

export default News;
