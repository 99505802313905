import React, { useEffect, useState } from "react";
import { Box, Grid, Modal, Typography } from "@mui/material";

function PhotoFullPage({ photo }) {
    const [photos, setPhotos] = useState([]);

    const [open, setOpen] = useState("");
    const [id, setId] = useState(-1);
    const [label, setLabel] = useState("");

    const handleClose = () => {
        setOpen("");
        setId(-1);
    };

    useEffect(() => {
        const loadPhotos = async () => {
            let indents = [];
            for (let i = 0; i < photo.maximo; i++) {
                indents.push(
                    <img
                        id={i}
                        onClick={(e) => {
                            setId(i)
                            setOpen(
                                photo.ID.length > 5
                                    ? `https://galeria-images.s3.sa-east-1.amazonaws.com/${photo.ID}/galeria/${i}_max.jpeg`
                                    : `https://galeria-images.s3.sa-east-1.amazonaws.com/${photo.ID}/galeria/${i}_max.jpg`
                            );
                        }}
                        src={
                            photo.ID.length > 5
                                ? `https://galeria-images.s3.sa-east-1.amazonaws.com/${photo.ID}/galeria/${i}_max.jpeg`
                                : `https://galeria-images.s3.sa-east-1.amazonaws.com/${photo.ID}/galeria/${i}_max.jpg`
                        }
                        width={"350px"}
                        height={"175px"}
                        alt="imagem de capa da noticia"
                        style={{
                            objectFit: "cover",
                            minWidth: "200px",
                        }}
                    />
                );
            }

            setPhotos(indents);
        };

        const loadPhotosList = async () => {
            let indents = photo.photos.map((item, i) => (
                <img
                    id={i}
                    onClick={(e) => {
                        setOpen(item.url);
                        setLabel(item.label);
                        setId(i)
                    }}
                    src={item.url}
                    width={"350px"}
                    height={"175px"}
                    alt="imagem de capa da noticia"
                    style={{
                        objectFit: "contain",
                        minWidth: "200px",
                        backgroundColor: '#000'
                    }}
                />
            ));

            setPhotos(indents);
        };

        if (photo.photos && photo.photos.length !== 0) {
            loadPhotosList();
        } else {
            loadPhotos();
        }
    }, []);

    function formatDate(date) {
        const months = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
        ];
        const newDate = new Date(date);

        return `${newDate.getDate()} de ${
            months[newDate.getMonth()]
        } de ${newDate.getFullYear()}`;
    }

    return (
        <Box marginBottom={2} p={4}>
            <Modal
                open={open === "" ? false : true}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        p: 4,
                    }}
                >
                    {parseInt(id) !== 0 && (
                        <Typography
                            color="#FFF"
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="700"
                            fontSize="48px"
                            lineHeight="37.57px"
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "20px",
                                transform: "translate(-50%, -50%)",
                            }}
                            onClick={() => {
                                const opened = parseInt(id) - 1;
                                if (opened >= 0) {
                                    setId(opened);
                                    if (photo.photos.length > 0) {
                                        setOpen(photo.photos[opened].url)
                                    } else {
                                    setOpen(photo.ID.length > 5
                                        ? `https://galeria-images.s3.sa-east-1.amazonaws.com/${photo.ID}/galeria/${opened}_max.jpeg`
                                        : `https://galeria-images.s3.sa-east-1.amazonaws.com/${photo.ID}/galeria/${opened}_max.jpg`)
                                    }
                                }
                            }}
                        >
                            {`<`}
                        </Typography>
                    )}
                    {parseInt(id) + 1 !== photo.maximo && (
                        <Typography
                            color="#FFF"
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="700"
                            fontSize="48px"
                            lineHeight="37.57px"
                            style={{
                                position: "absolute",
                                top: "50%",
                                right: "10px",
                                transform: "translate(-50%, -50%)",
                            }}
                            onClick={() => {
                                const opened = parseInt(id) + 1;
                                if (opened < photo.maximo) {
                                    setId(opened);
                                    if (photo.photos.length > 0) {
                                        setOpen(photo.photos[opened].url)
                                    } else {
                                    setOpen(photo.ID.length > 5
                                        ? `https://galeria-images.s3.sa-east-1.amazonaws.com/${photo.ID}/galeria/${opened}_max.jpeg`
                                        : `https://galeria-images.s3.sa-east-1.amazonaws.com/${photo.ID}/galeria/${opened}_max.jpg`)
                                    }
                                }
                            }}
                        >
                            {`>`}
                        </Typography>
                    )}
                    <img
                        onClick={handleClose}
                        src={open}
                        alt="imagem de capa da noticia"
                        style={{
                            objectFit: "cover",
                            minWidth: "200px",
                            maxWidth: "90vw",
                        }}
                    />
                    <Typography
                        color="#010101"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="500"
                        fontSize="24px"
                        lineHeight="37.57px"
                        backgroundColor="#FFFFFF90"
                        padding={label !== "" ? 1 : 0}
                    >
                        {label}
                    </Typography>
                </Box>
            </Modal>
            <Grid
                container
                display={"flex"}
                mt={2}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Grid xs={12} md={6}>
                    <Typography
                        color="#010101"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="32px"
                        lineHeight="37.57px"
                    >
                        {photo.titulo}
                    </Typography>
                    <Grid xs={12} md={12}>
                        <Typography
                            color="#010101"
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="400"
                            fontSize="12px"
                            lineHeight="14px"
                        >
                            {formatDate(photo.incData)}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    xs={12}
                    md={8}
                    gap={2}
                    marginTop={6}
                    container
                    marginY={6}
                    display={"flex"}
                >
                    {photos}
                </Grid>
            </Grid>
        </Box>
    );
}

export default PhotoFullPage;
